body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 60px;
  min-height: 100vh;
  padding-top: 0px;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.navbar-right {
  margin-right: 5%;
}
.navbar-left{
margin-left: 5%;
}

.content {
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  background-color: #d3d3d3;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

.left-panel {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right-panel {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title {
  color: #2b3a55;
  margin: 0;
}

.subtitle {
  margin: 10px 0 20px;
}

.image {
  width: 150px;
  margin-top: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input {
  padding: 10px;
  margin: 10px 0;
  width: 80%;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.same-width {
  width: calc(80% - 22px);
  box-sizing: border-box;
}

.button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #3b5998;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #2a4372;
}

.certificates-section {
  margin-top: 20px;
  text-align: center;
}

.certificates-title {
  margin-bottom: 20px;
}

.certificates {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.certificate-card {
  margin: 20px;
  text-align: center;
  background-color: #dadada;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.certificate-image {
  width: 50px;
  margin: 10px 0;
}

.certificate-name {
  display: block;
  margin: 10px 0;
}

.download-link {
  display: block;
  margin-top: 10px;
  padding: 10px;
  background-color: #3b5998;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.download-link:hover {
  background-color: #2a4372;
}

.no-certificates {
  margin-top: 20px;
}

.error {
  color: red;
  margin-top: 20px;
}

.instructions {
  margin-top: 20px;
}

.instructions-link {
  color: #3b5998;
  text-decoration: none;
  transition: color 0.3s;
}

.instructions-link:hover {
  color: #2a4372;
}

.inputs-container {
  display: flex;
  flex: 1;
}

.footer {
  background-color: #d83269;
  position: fixed;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 0;
  width: 100%;
  height: fit-content;
  color: white;
}

.link {
  color: white;
  text-decoration: none;
}